/* import fonts */
@import url('./Fonts.css');

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

/* set entire background */
html {
  /* background-color: #E9FBFF; */
  background-color: #FFFFFF;
}

/* class: text */
br {
  content: "";
  margin: 2%;
  display: block;
}
p {
  font-family: 'Open Sans';
  /* font-size: 1.6vw; */
  font-size: 20px;
  color: #3F3E3E;
  font-weight: 350;
}
.textImage {
  font-size: 15px;
}
.textSmall {
  font-size: 18px;
}
.textLarge {
  font-size: 23px;
}
.subtitle {
  font-family: 'Open Sans';
  /* font-size: 2vw; */
  font-size: 25px;
  color: #3F3E3E;
  font-weight: 500;
}
.title {
  font-family: 'Open Sans';
  font-size: 35px;
  color: #3F3E3E;
  font-weight: 500;
}
.titleFont {
  font-family: 'Allan';
  font-size: 3vw;
  font-weight: 500;
  color: #020E74;
}
.titleFontSmall {
  font-size: 2.5vw;
}
.textHover {
  border-bottom: 0.15em solid transparent;
  transition: border 0.5s;
}
.textHover:hover {
  border-bottom: 0.15em solid #0113b4;
}
.textIndent {
  text-indent: 2.4vw;
  /* text-indent: 30px; */
}
.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.textLeft {
  text-align: left;
}
.bold {
  font-weight: 700;
}
.italics {
  font-style: italic;
}
.textWhite {
  color: #FFFFFF;
}

/* class: containers */
.container {
  max-width: 100vw;
  height: auto;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 6vh;
  margin-bottom: 8vh;
  /* overflow-x: hidden; */
}
.noScroll {
  overflow: hidden;
}
.centerVertical {
  margin-top: auto;
  margin-bottom: auto;
}
.textSection {
  margin-top: 1%;
  margin-bottom: 3%;
}
.backContainer {
  position: fixed;
  margin-left: 2%;
  padding-top: 1%;
  left: 0;
}
.nextContainer {
  position: absolute;
  right: 0;
  width: 35%;
  margin-right: 5%;
  padding-top: 5vh;
  padding-bottom: 10vh;
  cursor: pointer;
}
.nextContainer:hover > :first-child {
  color: #020E74;
}
.nextContainer:hover > :last-child {
  color: #F4BB44;
}
.posRelative {
  position: relative;
}

/* class: divs */
.flexParent {
  display: flex;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flex3 {
  flex: 3;
}
.flex5 {
  flex: 5;
}
.div30 {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}
.centerItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.divBottom {
  position: absolute;
  bottom: 0;
}

/* class: spacing */
.spacingText {
  margin-bottom: 2%;
}
.spacingBot {
  margin-bottom: 5%;
}
.spacingBotLarge {
  margin-bottom: 8%;
}
.spBotDynamic2 {
  margin-bottom: 2vh;
}
.spacingTop2 {
  margin-top: 2%;
}
.spacingTop {
  margin-top: 5%;
}
.spacingTop15 {
  margin-top: 15%;
}
.spacingLeft {
  margin-left: 5%;
}
.spacingRight {
  margin-right: 5%;
}
.paddingBot {
  padding-bottom: 5%;
}

/* class: buttons */
.noLink {
  text-decoration: none;
}
.pointer {
  cursor: pointer;
}
button:hover {
  cursor: pointer;
}
.buttonBlank {
  background-color: inherit;
  border: none;
  text-decoration: none;
  border: inherit;  
  border-radius: inherit;
  padding: 0;
  margin: 0;
}

/* class: icons */
.socialIcons {
  color: #020E74;
  font-size: 3.4vw;
  padding: 2%;
  padding-bottom: 3%;
}
.socialIcons:hover {
  color: #0113b4;
}
.backIcon {
  color: #020E74;
  font-size: 3.4vw;
  padding: 2%;
  padding-bottom: 3%;
}
.backIcon:hover {
  color: #F4BB44;
}

/* class: grid */
.contentGrid {
  padding: 0%;
  padding-top: 3%;
  display: grid;
  column-gap: 10%;
  row-gap: 8%;
  grid-template-columns: repeat(2, 1fr);
}
.col2 {
  padding-top: 3%;
  padding-bottom: 3%;
  display: grid;
  column-gap: 5%;
  row-gap: 5%;
  grid-template-columns: repeat(2, 1fr);
}
.textGrid {
  display: grid;
  row-gap: 1rem;
}

/* class: images */
.gridImages {
  display: flex;
  justify-content: center;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1vw;
  cursor: pointer;
}
.portraitImage {
  display: flex;
  justify-content: center;
  object-fit: cover;
  max-width: 25%;
  max-height: 25%;
  margin-left: auto;
  margin-right: auto;
}
.fitImage {
  display: flex;
  justify-content: center;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.quarterImage {
  display: flex;
  justify-content: center;
  object-fit: cover;
  max-width: 25vw;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

/* class: list */
ul, ol {
  list-style-position: outside;
  margin-left: 1.2em;
}
li {
  margin-bottom: 1%;
}
a {
  text-decoration: none;
}

/* class: line */
hr {
  color: #B6B6B6;
  margin-top: 3vh;
  margin-bottom: 5vh;
}

/* Header */
.header {
  position: sticky;
  z-index: 11;
}
.headerTop {
  height: 2%;
  padding: 0.5%;
  background-color: #FFFFFF;
}
.headerTop a:link, a:visited, a:hover, a:active {
  color: #020E74;
}
.headerName {
  margin: 0;
  margin-left: 2%;
}
.headerBottom {
  position: absolute;
  left: 0;
  height: 5px;
  width: 100%;
  background-image: linear-gradient(to right, #2400FF, #11B6EB, #BCEEFF, #00EFE1, #11B6EB, #2400FF);
  background-size: 150%;
  animation: colourline 5s infinite linear;
}
@keyframes colourline {
  0% {background-position: left}
  50% {background-position: right}
  100% {background-position: left}
}
.headerRight {
  position: absolute;
  right: 0;
  margin-right: 2%;
}

/* Side Nav */
.sideNavContainer {
  position: fixed;
  margin-top: 5px;
  height: 95vh;
  width: 100vw;
  background-color: #FFFFFF;
  overflow-y: auto;
  z-index: 10;
  animation: slideDown 0.5s ease-out forwards; 
}
/* slide-down animation */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Footer */
#footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  text-align: center;
}

/* Nav Bar */
.nav {
  display: flex;
  text-align: center;
  margin-top: 2%;
  margin-left: 25%;
  margin-right: 25%;
}
.navUnit a:link, a:visited, a:hover, a:active {
  text-decoration: none;
  color: #3F3E3E;
}

/* Home */
.profileImage {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  /* box-shadow: 0 0 40px #CCD4FF; */
}
/* Animation */
.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  animation: typing 2s steps(30, end);
  transition: border infinite 5s;
  width: 20vw;
}
@keyframes typing {
  from { width: 0; border-right: .08em solid #B6B6B6;  }
  to { width: 20vw; border-right: .08em solid #B6B6B6; }
  step-end { border-right: transparent;  }
}


/* Pages */
.imageContainer {
  width: 95%;
  height: 95%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1em;
  position: relative;
  display: inline-block; 
}
.imageOverlay {
  position: absolute;
  bottom: 0;
  width: 100%; 
  height: 100%; 
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  color: white;
  text-align: center;
  opacity: 0; 
  transition: opacity 0.35s ease; 
}
.textOverlay {
  display: flex;
  justify-content: center;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  margin-left: 5%;
  margin-right: auto;
  border-radius: 1vw;
  cursor: pointer;
}
.imageContainer:hover .imageOverlay {
  opacity: 1; 
}
.textFrame {
  margin-left: 1%;
  margin-right: 1%;
  padding: 2%;
  padding-left: 5%;
  padding-right: 5%;
  border: 0.2em solid #F4BB44;
  border-radius: 1.5em;
}
.textFrameBlue {
  padding: 2%;
  padding-left: 5%;
  padding-right: 5%;
  border: 0.1em solid #11B6EB;
  border-radius: 1.5em;
}
.line {
  border: 2px solid #B6B6B6;
  border-radius: 10px;
	align-items: center;
  margin: auto;
  width: 68vw;
  z-index: 1;
}
.dot {
  cursor: pointer;
  height: 25px;
  width: 25px;
  background-color: #52ACFF;
  border-radius: 50%;
}
.dotPosition {
  border: solid 0.1rem #FFFFFF;
  margin-top: -7.5vh;
  position: absolute;
  z-index: 5;
}

/* class: highlight */
.underlineYellow {
  text-decoration: solid underline #F4BB44;
}
.highlightYellow {
  background-color: rgba(244, 187, 68, 0.7);
}
.borderPurple {
  border-color: #5D3FD3;
  /* border-width: 3px;
  border-style: solid;  */
  /* border-image: linear-gradient(to right, #ff0000, #0000ff);
  border-image-slice: 1;
  border-radius: 0.2em; */
  background: linear-gradient(white, white) padding-box,
              linear-gradient(125deg, darkblue, #7F00FF, #5D3FD3, #CF9FFF) border-box;
  border-radius: 2em;
  border: 4px solid transparent;
}
.borderBlue {
  border-color: #2400FF;
  background: linear-gradient(white, white) padding-box,
              linear-gradient(25deg, #2400FF, #11B6EB, #BCEEFF, #00EFE1) border-box;
  border-radius: 2em;
  border: 4px solid transparent;
}


/* DYNAMIC STYLES */
/* Small */
@media screen and (max-width: 650px) {
  p {
    font-size: 18px;
  }
  .textSmall {
    font-size: 16px;
  }
  .textLarge {
    font-size: 20px;
  }
  .title {
    font-size: 30px;
  }
  .titleFont {
    font-size: 8vw;
  }
  .titleFontSmall {
    font-size: 6vw;
  }
  .socialIcons {
    font-size: 6.8vw;
  }
  .backIcon {
    font-size: 6vw;
  }
  .container {
    padding-bottom: 10%;
  }
  .nextContainer {
    width: 55%;
    padding-top: 10vh;
  }
  .textSection {
    margin-top: 10%;
  }
  .contentGrid, .col2 {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 10%;
  }
  .typewriter {
    width: 51.5vw;
  }
  @keyframes typing {
    from { width: 0; border-right: .08em solid #B6B6B6;  }
    to { width: 51.5vw; border-right: .08em solid #B6B6B6; }
    step-end { border-right: transparent;  }
  }
}
/* Large */
@media screen and (min-width: 650px) {

}